
.panel {
    max-height: 300px;

    .website-link {
        display: none;
    }

    h1 {
        font-family: Avenir;
        font-size: 19px;
        font-weight: 500;
        color: #141414;
        text-overflow: ellipsis;
        margin-bottom: 0;
    }

    .buttons {
        display: grid;
        grid-template-columns: 50% 50%;

        &.no-website {
            grid-template-columns: 100%;
        }

        button {
            color: #9b9b9b;
            border: 1px solid #9b9b9b;
            border-radius: 5px;
            background-color: white;
            margin: 10px 6px;
            padding: 6px 10px;

            &.directions {
                margin-left: 0;
            }

            &.website-link {
                margin-right: 0;
            }

            &:hover {
                background-color: darken(white, 10%);
                color: darken(#9b9b9b, 10%);
            }
        }
    }

    .feature-description {
        margin-top: 10px;
        font-size: 19px;
        line-height: 1.26;
        color: #505152;

        white-space: pre-wrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &.expanded {
        max-height: none;

        .website-link {
            display: block;
        }

        .feature-description {
            white-space: pre-wrap;
            overflow: visible;
            text-overflow: unset;
        }

        .layer-details-mobile-view & {
            max-height: 100%;

            .feature-description {
                overflow: scroll;
            }
        }
    }
}
